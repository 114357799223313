"use restrict"; //Number of bits in an integer

var exports = {};
var INT_BITS = 32; //Constants

exports.INT_BITS = INT_BITS;
exports.INT_MAX = 2147483647;
exports.INT_MIN = -1 << INT_BITS - 1; //Returns -1, 0, +1 depending on sign of x

exports.sign = function (v) {
  return (v > 0) - (v < 0);
}; //Computes absolute value of integer


exports.abs = function (v) {
  var mask = v >> INT_BITS - 1;
  return (v ^ mask) - mask;
}; //Computes minimum of integers x and y


exports.min = function (x, y) {
  return y ^ (x ^ y) & -(x < y);
}; //Computes maximum of integers x and y


exports.max = function (x, y) {
  return x ^ (x ^ y) & -(x < y);
}; //Checks if a number is a power of two


exports.isPow2 = function (v) {
  return !(v & v - 1) && !!v;
}; //Computes log base 2 of v


exports.log2 = function (v) {
  var r, shift;
  r = (v > 65535) << 4;
  v >>>= r;
  shift = (v > 255) << 3;
  v >>>= shift;
  r |= shift;
  shift = (v > 15) << 2;
  v >>>= shift;
  r |= shift;
  shift = (v > 3) << 1;
  v >>>= shift;
  r |= shift;
  return r | v >> 1;
}; //Computes log base 10 of v


exports.log10 = function (v) {
  return v >= 1000000000 ? 9 : v >= 100000000 ? 8 : v >= 10000000 ? 7 : v >= 1000000 ? 6 : v >= 100000 ? 5 : v >= 10000 ? 4 : v >= 1000 ? 3 : v >= 100 ? 2 : v >= 10 ? 1 : 0;
}; //Counts number of bits


exports.popCount = function (v) {
  v = v - (v >>> 1 & 1431655765);
  v = (v & 858993459) + (v >>> 2 & 858993459);
  return (v + (v >>> 4) & 252645135) * 16843009 >>> 24;
}; //Counts number of trailing zeros


function countTrailingZeros(v) {
  var c = 32;
  v &= -v;
  if (v) c--;
  if (v & 65535) c -= 16;
  if (v & 16711935) c -= 8;
  if (v & 252645135) c -= 4;
  if (v & 858993459) c -= 2;
  if (v & 1431655765) c -= 1;
  return c;
}

exports.countTrailingZeros = countTrailingZeros; //Rounds to next power of 2

exports.nextPow2 = function (v) {
  v += v === 0;
  --v;
  v |= v >>> 1;
  v |= v >>> 2;
  v |= v >>> 4;
  v |= v >>> 8;
  v |= v >>> 16;
  return v + 1;
}; //Rounds down to previous power of 2


exports.prevPow2 = function (v) {
  v |= v >>> 1;
  v |= v >>> 2;
  v |= v >>> 4;
  v |= v >>> 8;
  v |= v >>> 16;
  return v - (v >>> 1);
}; //Computes parity of word


exports.parity = function (v) {
  v ^= v >>> 16;
  v ^= v >>> 8;
  v ^= v >>> 4;
  v &= 15;
  return 27030 >>> v & 1;
};

var REVERSE_TABLE = new Array(256);

(function (tab) {
  for (var i = 0; i < 256; ++i) {
    var v = i,
        r = i,
        s = 7;

    for (v >>>= 1; v; v >>>= 1) {
      r <<= 1;
      r |= v & 1;
      --s;
    }

    tab[i] = r << s & 255;
  }
})(REVERSE_TABLE); //Reverse bits in a 32 bit word


exports.reverse = function (v) {
  return REVERSE_TABLE[v & 255] << 24 | REVERSE_TABLE[v >>> 8 & 255] << 16 | REVERSE_TABLE[v >>> 16 & 255] << 8 | REVERSE_TABLE[v >>> 24 & 255];
}; //Interleave bits of 2 coordinates with 16 bits.  Useful for fast quadtree codes


exports.interleave2 = function (x, y) {
  x &= 65535;
  x = (x | x << 8) & 16711935;
  x = (x | x << 4) & 252645135;
  x = (x | x << 2) & 858993459;
  x = (x | x << 1) & 1431655765;
  y &= 65535;
  y = (y | y << 8) & 16711935;
  y = (y | y << 4) & 252645135;
  y = (y | y << 2) & 858993459;
  y = (y | y << 1) & 1431655765;
  return x | y << 1;
}; //Extracts the nth interleaved component


exports.deinterleave2 = function (v, n) {
  v = v >>> n & 1431655765;
  v = (v | v >>> 1) & 858993459;
  v = (v | v >>> 2) & 252645135;
  v = (v | v >>> 4) & 16711935;
  v = (v | v >>> 16) & 65535;
  return v << 16 >> 16;
}; //Interleave bits of 3 coordinates, each with 10 bits.  Useful for fast octree codes


exports.interleave3 = function (x, y, z) {
  x &= 1023;
  x = (x | x << 16) & 4278190335;
  x = (x | x << 8) & 251719695;
  x = (x | x << 4) & 3272356035;
  x = (x | x << 2) & 1227133513;
  y &= 1023;
  y = (y | y << 16) & 4278190335;
  y = (y | y << 8) & 251719695;
  y = (y | y << 4) & 3272356035;
  y = (y | y << 2) & 1227133513;
  x |= y << 1;
  z &= 1023;
  z = (z | z << 16) & 4278190335;
  z = (z | z << 8) & 251719695;
  z = (z | z << 4) & 3272356035;
  z = (z | z << 2) & 1227133513;
  return x | z << 2;
}; //Extracts nth interleaved component of a 3-tuple


exports.deinterleave3 = function (v, n) {
  v = v >>> n & 1227133513;
  v = (v | v >>> 2) & 3272356035;
  v = (v | v >>> 4) & 251719695;
  v = (v | v >>> 8) & 4278190335;
  v = (v | v >>> 16) & 1023;
  return v << 22 >> 22;
}; //Computes next combination in colexicographic order (this is mistakenly called nextPermutation on the bit twiddling hacks page)


exports.nextCombination = function (v) {
  var t = v | v - 1;
  return t + 1 | (~t & -~t) - 1 >>> countTrailingZeros(v) + 1;
};

export default exports;
export const INT_MAX = exports.INT_MAX,
      INT_MIN = exports.INT_MIN,
      sign = exports.sign,
      abs = exports.abs,
      min = exports.min,
      max = exports.max,
      isPow2 = exports.isPow2,
      log2 = exports.log2,
      log10 = exports.log10,
      popCount = exports.popCount,
      nextPow2 = exports.nextPow2,
      prevPow2 = exports.prevPow2,
      parity = exports.parity,
      reverse = exports.reverse,
      interleave2 = exports.interleave2,
      deinterleave2 = exports.deinterleave2,
      interleave3 = exports.interleave3,
      deinterleave3 = exports.deinterleave3,
      nextCombination = exports.nextCombination;
const _INT_BITS = exports.INT_BITS,
      _countTrailingZeros = exports.countTrailingZeros;
export { _INT_BITS as INT_BITS, _countTrailingZeros as countTrailingZeros };